import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Footer = () => {

    const data = useStaticQuery(graphql`
    query {
        UI {
            dsFooter {
                DS_FooterLogo {
                  url
                  alternativeText
                }
                DS_FooterDescription
                DSFooter_Copyrights
                DS_FooterSocialLink {
                  Black_Icons {
                    url
                    alternativeText
                  }
                  White_Icons {
                    url
                    alternativeText
                  }
                  Social_Link
                }
                FooterMenu {
                    MenuHeading
                    SubMenus {
                      DS_Menu
                      DS_MenuURL
                    }
                }
            }   
        }
    }`)
    const footer = data.UI.dsFooter
    
    return (
        <footer className="mainFooter">
            <div className="container container-modified">
                <div className="row">
                    <div className="col-lg-4 footerLogo">
                        <div className="divFooterLogo">
                            <Link className="navbar-brand" exact to="/" rel="noreferrer">
                                {footer.DS_FooterLogo ? (
                                    <img src={footer.DS_FooterLogo.url} alt={footer.DS_FooterLogo.alternativeText} style={{width:'130px'}} />
                                ) : null}
                            </Link>
                        </div>                        
                        {footer.DS_FooterDescription ? (
                            <div className="footer_about">
                                <p dangerouslySetInnerHTML={{ __html: footer.DS_FooterDescription }} />
                            </div>
                        ) : null}
                        <div className=" footerSocial">
                            <ul className="footerSocialNav">
                                {footer.DS_FooterSocialLink && footer.DS_FooterSocialLink.map((social, i) => (
                                    <li className="nav-item" key={i}>
                                        <Link className="nav-link" exact to={social.Social_Link} target="_blank">
                                            {social.Black_Icons ? (
                                                <img src={social.Black_Icons.url} alt={social.Black_Icons.alternativeText} />
                                            ) : null}
                                            {social.White_Icons ? (
                                                <img src={social.White_Icons.url} alt={social.White_Icons.alternativeText} />
                                            ) : null}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>                                                
                    </div>      
                    <div className="col-lg-1"></div>              
                    <div className="col-lg-7 col-md-12 footer_main_menu">
                        <div className="row">
                            {footer.FooterMenu && footer.FooterMenu.map((menu, i) => (
                               <div className="col-md-4 footer_menu_padd_mob" key={i}>
                                   {menu.MenuHeading ? (<h4 className="footer_menu_title">{menu.MenuHeading}</h4>) : null}
                                   <ul className="footer_sub_nav navbar-nav">
                                    {menu.SubMenus && menu.SubMenus.map((list, m) => (
                                        <li className="nav-item" key={m}>
                                            <Link className="nav-link" exact to={list.DS_MenuURL} target={list.DS_MenuURL === '/work' || list.DS_MenuURL === '/contact'  ? '' : '_blank'}>{list.DS_Menu}</Link>
                                        </li>
                                    ))}
                                   </ul>
                               </div> 
                            ))}
                        </div>
                    </div>                      
                </div>
            </div>
        </footer> 
    )
}

export default Footer