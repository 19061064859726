import * as React from "react"
import PropTypes from "prop-types"
import "../assets/css/layout.css"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
    return (
        <div className="divMain">
            <Header />
                { children }
            <Footer />
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
  
export default Layout