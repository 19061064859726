import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router';

const Head = ({SEO_Tags}) => {
	const location = useLocation();
    const headSeo = SEO_Tags
    return (
		<Helmet>
			<title>{headSeo.Meta_Title}</title>
			<link rel="canonical" href={location.href} />
			<meta name="description" content={headSeo.Meta_Description} />
        </Helmet>
    )
}

export default Head;