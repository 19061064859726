import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Navbar, Nav} from "react-bootstrap";

const Header = () => {

    const data = useStaticQuery(graphql`
    query {
        UI {
            dsHeader {
              DS_logo {
                url
                alternativeText
              }
              Menu {
                DS_Menu
                DS_MenuURL
              }
            }    
        }
    }`)
    const mainMenus = data.UI.dsHeader
    
    return (
        <div className="headerMainNavigation">
            <Navbar collapseOnSelect className="navbar navbar-expand-lg navbar-light" expand="md" variant="dark" id="menu_nav_bar">
                <div className="container container-modified">
                    <Link className="navbar-brand" exact to="/">
                        <img src={mainMenus.DS_logo ? mainMenus.DS_logo.url : null} alt={mainMenus.DS_logo ? mainMenus.DS_logo.alternativeText : null} style={{'width':"130px"}} />
                    </Link>
                    <Navbar.Toggle aria-controls="navbarResponsive" />
                        <Navbar.Collapse id="navbarResponsive">
                            <Nav className="ms-auto"> 		    	
                                <ul className="navbar-nav">
                                    { mainMenus && mainMenus.Menu.map((menu, i) => (
                                        <li className={menu.DS_Menu === 'Contact Us' ? 'nav-item contact_Item' : 'nav-item'} key={i}>
                                            <Link exact to={menu.DS_MenuURL} className={menu.DS_Menu === 'Contact Us' ? 'nav-link contact_Item' : 'nav-link'}> {menu.DS_Menu} </Link>	             
                                        </li>
                                    ))} 
                                </ul>
                            </Nav>
                        </Navbar.Collapse>
                </div>
            </Navbar>
        </div>
    )
}

export default Header